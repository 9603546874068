import { lazy, Suspense, useContext } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { AuthContext } from "./Hooks/Context";
import "primereact/resources/themes/lara-light-cyan/theme.css";
// admin
const Admin = lazy(() => import("./pages/Admin/Admin"));

const Login = lazy(() => import("./pages/Login/Login"));
const NotFound = lazy(() => import("./pages/NotFound/NotFound"));
const Discount = lazy(() => import("./pages/Admin/Discount/Discount"));
const Products = lazy(() => import("./pages/Admin/Products/Products"));
const Category = lazy(() => import("./pages/Admin/Category/Category"));
const CategoryDetails = lazy(() => import("./pages/Admin/Category/Details"));
const Order = lazy(() => import("./pages/Admin/Order/Order"));
const Home = lazy(() => import("./pages/Admin/Home/Home"));

function App() {
  const location = useLocation();
  let access_token = Cookies.get("access_token");
  const { loader } = useContext(AuthContext);
  return (
    <div className="App">
      {loader && (
        <div className="bg_loader">
          <span className="loader"></span>
        </div>
      )}
      <Suspense fallback={<></>}>
        <Routes location={location} key={location.pathname}>
          <Route index path="/Login" element={<Login />}></Route>
          <Route path="/" element={<Login />}></Route>
          <Route path="/*" element={<Navigate to={"/NotFound"} />}></Route>
          <Route path="*" element={<Navigate to={"/NotFound"} />}></Route>
          <Route path="/NotFound" element={<NotFound />}></Route>
          {access_token && (
            <Route path="HomePage" element={<Admin />}>
              <Route path="Home" element={<Home />}></Route>
              <Route path="Discount" element={<Discount />}></Route>
              <Route path="Products" element={<Products />}></Route>
              <Route path="Category" element={<Category />}></Route>
              <Route path="Order" element={<Order />}></Route>
              <Route path="Category/:id" element={<CategoryDetails />}></Route>
            </Route>
          )}
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
