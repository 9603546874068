const BASIC_URL = "https://back-end.mirruba-jewellery.com/public/index.php/api";
// const BASIC_URL = "http://192.168.43.69:8000/api";
const URL_Image = "https://back-end.mirruba-jewellery.com/public";
// const URL_Image = "http://192.168.43.69:8000";

const Config = {
  api: {
    Admin: {
      Login: `${BASIC_URL}/v1/login`,
      logout: `${BASIC_URL}/v1/cms/logout`,
      Password_change: `${BASIC_URL}/v1/cms/password/change`,
      profile_update: `${BASIC_URL}/v1/cms/profile/update`,
      profile: `${BASIC_URL}/v1/cms/profile`,
      v1_cms_active_change: `${BASIC_URL}/v1/cms/active/change`,
      Image: `${URL_Image}`,
    },
    Coupon: {
      v1_cms_coupon: `${BASIC_URL}/v1/cms/coupon`,
    },
    Category: {
      v1_cms_category: `${BASIC_URL}/v1/cms/category`,
      v1_cms_product_category: `${BASIC_URL}/v1/cms/product/category`,
    },
    Product: {
      v1_cms_product: `${BASIC_URL}/v1/cms/product`,
    },
    Order: {
      v1_cms_order: `${BASIC_URL}/v1/cms/order`,
    },
  },
};
export const API = Config.api;
